<template>
  <div
    class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto"
    style="background-color: #fffffff7"
  >
    <Form
      class="form w-100"
      id="kt_login_signin_form"
      ref="formLogin"
      @submit="onSubmitLogin"
      :validation-schema="login"
    >
      <div class="text-center mb-10">
        <img alt="Logo" src="media/logos/logo_arkheia_rvb.png" class="h-65px" />
        <!--end::Title-->
      </div>

      <div class="fv-row mb-10 p-2" style="background: #ecf5ff; text-align: center;">
        
        <label class="form-label fs-4 fw-bolder text-dark" style="text-transform: uppercase;" v-if="!state.codeArkError">{{ state.codeArkSociete }}</label>
        <div class="fv-plugins-message-container" v-if="state.codeArkError">
          <div class="fv-help-block fs-5">
            <b>{{ state.codeArkError }}</b>
          </div>
        </div>
      </div>
      
      <div class="fv-row mb-10" v-show="!state.loaderEnabled">
        
        <label class="form-label fs-6 fw-bolder text-dark">Email</label>

        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          name="mail"
          v-model="state.mail"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="mail" />
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap justify-content-center pb-lg-0">
        <router-link
          to="/sign-in"
          class="btn btn-lg btn-light-primary fw-bolder me-4"
          >Annuler</router-link
        >
        <button
          type="submit"
          ref="submitButton"
          id="kt_password_reset_submit"
          class="btn btn-lg btn-primary"
        >
          <span class="indicator-label"> Réinitialiser votre mot de passe </span>

          <span class="indicator-progress">
            Chargement...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      codeArk :"",
      codeArkError : '',
      codeArkSociete : '',
      loaderEnabled: true,
      mail: ""
    });

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(async () => {

      const mCodeArk = window.localStorage.getItem('codeArk');
      if (mCodeArk) {
        state.codeArk = mCodeArk;
        fCodeArk();
      }

      if (state.codeArk == "") {
        state.codeArkError = "Le code Ark est obligatoire"
        return false;
      }
      fCodeArk();
    });

      const fCodeArk = async () => {

      const rCodeArk = await getAxios("/getCodeArk/" + state.codeArk);
      if (rCodeArk.results == 'KO') {
        state.codeArkError = "Le code Ark est inconnu, veuillez le vérifier svp."
        return false;
      }
      window.localStorage.setItem('codeArk', state.codeArk);
      state.codeArkSociete = rCodeArk.results;
      state.loaderEnabled = false;
    }

    //Create form validation object
    const login = Yup.object().shape({
      mail: Yup.string()
        .email("L'email doit être valide")
        .required("L'email est obligatoire")
        .label("mail")
    });


    //Form submit function
    const onSubmitLogin = async () => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const response = await getAxios("/sendNewPassword/" + state.mail)
        if (response.results == "ok") {
          Swal.fire({
            text: "Si votre mot de passe existe dans notre base de données, un lien vers la réinitialisation de votre mot de passe sera envoyé mar email.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Retourner sur la page de connexion",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function (){
            router.push("/sign-in")
          });
        }else{
          Swal.fire({
            text: "Une erreur s'est produite, veuillez réessayer.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Retourner sur la page de connexion",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(function (){
            router.push("/sign-in")
          });
        }
      }
    }

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
