
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      codeArk :"",
      codeArkError : '',
      codeArkSociete : '',
      loaderEnabled: true,
      mail: ""
    });

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(async () => {

      const mCodeArk = window.localStorage.getItem('codeArk');
      if (mCodeArk) {
        state.codeArk = mCodeArk;
        fCodeArk();
      }

      if (state.codeArk == "") {
        state.codeArkError = "Le code Ark est obligatoire"
        return false;
      }
      fCodeArk();
    });

      const fCodeArk = async () => {

      const rCodeArk = await getAxios("/getCodeArk/" + state.codeArk);
      if (rCodeArk.results == 'KO') {
        state.codeArkError = "Le code Ark est inconnu, veuillez le vérifier svp."
        return false;
      }
      window.localStorage.setItem('codeArk', state.codeArk);
      state.codeArkSociete = rCodeArk.results;
      state.loaderEnabled = false;
    }

    //Create form validation object
    const login = Yup.object().shape({
      mail: Yup.string()
        .email("L'email doit être valide")
        .required("L'email est obligatoire")
        .label("mail")
    });


    //Form submit function
    const onSubmitLogin = async () => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const response = await getAxios("/sendNewPassword/" + state.mail)
        if (response.results == "ok") {
          Swal.fire({
            text: "Si votre mot de passe existe dans notre base de données, un lien vers la réinitialisation de votre mot de passe sera envoyé mar email.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Retourner sur la page de connexion",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function (){
            router.push("/sign-in")
          });
        }else{
          Swal.fire({
            text: "Une erreur s'est produite, veuillez réessayer.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Retourner sur la page de connexion",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(function (){
            router.push("/sign-in")
          });
        }
      }
    }

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
